<template>
  <div class="tipp-clip">
    <img class="close-tipp-clip" src="../assets/tipp/tipp-cross.svg" @click="$router.go(-1)">
    <iframe :src="url" class="tipp-clip-frame"></iframe>
  </div>
</template>

<script>
import data from "@/data/data-tree.js";

    export default {
        name: "TippClip",
        data() {
            return {
                url: ""
            }
        },
        mounted() {
            this.url = data['tipps' + this.$i18n.locale][this.$route.params.id].tippClip

            //this.openInNewTab(this.url)
            //this.$router.push('/tipp/' + this.$route.params.id)
        },
        methods: {
            /*openInNewTab(url) {
                var win = window.open(url, '_blank');
                win.focus();
            }*/
        },
    }
</script>

<style lang="scss" scoped>
  .tipp-clip {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 70px;
  }

  .close-tipp-clip {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 45px;
    cursor: pointer;

    @include mobile-down {
      width: 35px;
    }
  }

  .tipp-clip-frame {
    width: 100%;
    height: 100%;
    border: black 4px solid;
  }
</style>